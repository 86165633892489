var startPos = 0,winScrollTop = 0;
$(window).on('scroll',function(){
    winScrollTop = $(this).scrollTop();
    if (winScrollTop >= startPos) {
        $('.fullScreenMenu-button').addClass('hide');
        $('.c-fixed-logo').addClass('hide');
    } else {
        $('.fullScreenMenu-button').removeClass('hide');
        $('.c-fixed-logo').removeClass('hide');
    }
    startPos = winScrollTop;
});