// import {TweenMax} from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';


var controller = new ScrollMagic.Controller();

var scene_lead = new ScrollMagic.Scene({
  triggerElement: "#lead" ,
  triggerHook: 'onCenter',
  // duration: 800,
  offset :200
})
// .addIndicators()
.addTo(controller);

scene_lead.on('enter', ()=>{
//triggerElementを過ぎたとき
document.querySelector(".c-abstract").classList.add("is-active");
document.querySelector(".c-fixed-logo").classList.add("is-active");
document.querySelector(".top-fixed-contents-side-bottom").classList.add("is-active");
document.querySelector(".fullScreenMenu-button").classList.add("is-active");
document.querySelector(".c-fixed-bnr").classList.add("is-active");
});
scene_lead.on('leave', ()=>{
  //triggerElementを抜けたとき
  document.querySelector(".c-abstract").classList.remove("is-active");
  document.querySelector(".c-fixed-logo").classList.remove("is-active");
  document.querySelector(".top-fixed-contents-side-bottom").classList.remove("is-active");
  document.querySelector(".fullScreenMenu-button").classList.remove("is-active");
  document.querySelector(".c-fixed-bnr").classList.remove("is-active");
});
