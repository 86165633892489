import Stickyfill from "../../plugins/stickyfill.js";

var elements = document.querySelectorAll(".sticky");
Stickyfill.add(elements);

window.onscroll = function () {
  scrollToggleClass("#area01", "js-area-active", "-area01");
  scrollToggleClass("#area02", "js-area-active", "-area02");
  scrollToggleClass("#area03", "js-area-active", "-area03");
  scrollToggleClass("#area04", "js-area-active", "-area04");
  scrollToggleClass("#area05", "js-area-active", "-area05");
  scrollToggleClass("#area06", "js-area-active", "-area06");
};

function scrollToggleClass(rangeTarget, classname, classname2) {
  if ($(rangeTarget).length) {
    let result = Number(rangeTarget.substr(6));
    result = "#area0" + (result + 1);
    let nav = ".top-fixed-contents-side-menu__item";
    let nav2 = ".l-header-fullscreen-nav__item";

    let scroll = $(window).scrollTop() + 200;
    let startPos = $(rangeTarget).offset().top;

    let startPosOut = $(".js-fixed-contents").offset().top;
    let endPosOut = $("#area06").offset().top;
    let endPos = 0;

    if (rangeTarget == "#area06") {
      endPos = startPos + $(rangeTarget).outerHeight();
    } else {
      endPos = $(result).offset().top;
    }

    if (scroll > startPos && scroll < endPos) {
      $(rangeTarget).addClass(classname);
      $(".top-fixed-contents-side-bottom").addClass(classname2);
      $(nav + "." + classname2).addClass(classname);
      $(nav2 + "." + classname2).addClass(classname);
    } else {
      $(rangeTarget).removeClass(classname);
      $(".top-fixed-contents-side-bottom").removeClass(classname2);
      $(nav + "." + classname2).removeClass(classname);
      $(nav2 + "." + classname2).removeClass(classname);
    }
  }
}


function scrollToggleClass2(rangeTarget2, classname3, navname) {
  if ($(rangeTarget2).length) {
    let result2 = Number(rangeTarget2.substr(6));
    result2 = "#text0" + (result2 + 1);
    let nav3 = ".top-background-image__inner";

    let scroll2 = $(window).scrollTop() + 200;
    let startPos2 = $(rangeTarget2).offset().top;

    let startPosOut2 = $(".js-fixed-image").offset().top;
    let endPosOut2 = $("#text03").offset().top;
    let endPos2 = 0;

    if (rangeTarget2 == "#text03") {
      endPos2 = startPos2 + $(rangeTarget2).outerHeight();
    } else {
      endPos2 = $(result2).offset().top;
    }

    if (scroll2 > startPos2 && scroll2 < endPos2) {
      $(rangeTarget2).addClass(classname3);
      $(nav3).find("." + navname).addClass(classname3);
    } else {
      $(rangeTarget2).removeClass(classname3);
      $(nav3).find("." + navname).removeClass(classname3);
    }
  }
}
