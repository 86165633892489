//-----------------------------------------------
//スクロール禁止
//-----------------------------------------------

// // スクロール禁止
function no_scroll() {
  // PCでのスクロール禁止
  document.addEventListener("mousewheel", scroll_control, { passive: false });
  // スマホでのタッチ操作でのスクロール禁止
  document.addEventListener("touchmove", scroll_control, { passive: false });
}
// // スクロール禁止解除
function return_scroll() {
  // PCでのスクロール禁止解除
  document.removeEventListener("mousewheel", scroll_control, {
    passive: false,
  });
  // スマホでのタッチ操作でのスクロール禁止解除
  document.removeEventListener("touchmove", scroll_control, { passive: false });
}

// // スクロール関連メソッド
function scroll_control(event) {
  event.preventDefault();
}

//-----------------------------------------------
//ロード時起動
//-----------------------------------------------

no_scroll();
var time = new Date().getTime();

$(function() {
  //デバイスの高さを反映
  var windowWidth = $(window).width();
  var windowHeight = $(window).height();
  $("#loading").css("width", windowWidth);
  $("#loading").css("height", windowHeight);
});

//全ての読み込みが完了したら実行
$(window).on("load", function () {

  var now = new Date().getTime();
  if (now - time <= 1000) {
    setTimeout(loadAnimationStep1, 2000 - (now - time));
    return;
  } else {
    loadAnimationStep1();
  }
});

//10秒たったら強制的にロード画面を非表示
$(function(){
  setTimeout(loadAnimationStop,5000);
});

//-----------------------------------------------
//ロードアニメーション
//-----------------------------------------------

function loadAnimationStep1() {

  var animationEvt = 'webkitAnimationEnd mozAnimationEnd msAnimationEnd oAnimationEnd animationEnd';

  $(".loading-first").addClass("load-completed");
  setTimeout(function(){
    $(".loading-second").addClass("active");
  }, 500);

  $('.loading-second').on(animationEvt, function() {
    loadAnimationStop();
  });
}

function loadAnimationStop() {
  let loadok = $("#loading").hasClass("load-completed");

  if (!loadok) {
    document.querySelector("body").classList.add("load-completed");
  }
  return_scroll();
}
