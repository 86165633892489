import Simplemodal from '../../plugins/simplemodal.js';

const fullScreenMenu = new Simplemodal({
  trigger : "data-fullScreenMenu-trigger",
  clickOutSideClose : true,
  backFixed: false,
})


const triggers = document.querySelectorAll([".js-menu-achor"]);

triggers.forEach((trigger, index) => {
  trigger.addEventListener("click", (e) => {
    closeAction();
    $(".fullScreenMenu-button").attr("aria-expanded", "false");
    $(".fullScreenMenu-button").removeClass("is-open");
  });
});

function closeAction() {
  const triggers = document.querySelectorAll(["data-fullscreenmenu-trigger"]);
  const panels = document.querySelectorAll("#js-fullScreenMenu-content");
  const button = document.querySelector(".fullScreenMenu-button");

  triggers.forEach((trigger, index) => {
    trigger.setAttribute("aria-expanded", "false");
    trigger.classList.remove("is-open");
  });

  panels.forEach((panel, index) => {
    panel.setAttribute("aria-hidden", "true");
    panel.classList.remove("is-open");
  });
}